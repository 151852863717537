export default function getBaseURL() {

  // DEFINE SERVER URL
  //const baseURL = "http://192.168.0.161:8080"; // NETWORK URL 

  const baseURL = "https://rentalcottagesapi.onrender.com"; // PRODUCTION URL

  //const baseURL = "http://localhost:8080"; // DEVELOPMENT URL 

  return baseURL;
};